/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../../common/Button";
import ContactUs from "../../common/ContactUs";
import Customers from "../../common/Customers";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import Section from "../../common/Section";
import ProductsCallout from "../../products/ProductsCallout";
import ProductsHero from "../../products/ProductsHero";
import KuromojiSection from "../../products/ProductsKuromoji";
import {H1} from "../../common/typography/Headings";

const ProductsJa = () => (
    <Page path="/ja/products/" lang="ja" title="Products">
        <Meta name="description">東アジアと西洋の言語のための自然言語処理用ツール</Meta>
        <ProductsHero lang="ja" path="/ja/products/">
            <H1>製品</H1>
            <p>東アジアと西洋の言語のための 自然言語処理用ツール</p>
        </ProductsHero>
        <Intro>
            <p>
                私たちのオープンソースと独自技術の自然言語処理製品は世界中の主流企業のビジネスを支えています。
                独自技術の製品は制限なく利用及びソース変更を可能とする柔軟なライセンスで提供しております。
                いつでも弊社の製品デモをさせて頂きます。試用ライセンスもご用意いたします。
            </p>
        </Intro>
        <KuromojiSection title="Kuromoji">
            <p>
                Kuromojiは私たちがJavaで開発したオープンソースの日本語形態素解析器です。基本機能は単語の分割、品詞タグ付け、
                見出し化、漢字の読み方であり、複数の辞書のバックエンドをサポートしています。
                KuromojiはApache Lucene、Apache
                Solrに採用されていますし、Elasticsearchにも使われています。
                Kuromojiのデモ及び詳細な情報をご希望の方はご連絡ください。
            </p>
            <p>
                Apache ライセンス
                v2と共に、独自ライセンスとサポートのC言語バージョンも提供しております。
            </p>
            <p>
                <Button to="/ja/kuromoji/" readMore>
                    more
                </Button>
            </p>
        </KuromojiSection>
        <Section title="Akahai">
            <p>
                Akahaiは私たちが日本語、中国語、韓国語及び西洋の言語のため開発したクエリサジェストエンジンです。
                Akahaiは検索または他のテキスト入力時に、即時に高品質のクエリサジェストを提供します。Akahaiは入力変換の途中でも
                ひらがな、カタカナ、漢字、ローマ字を跨ったクエリ一致を返します。さらに、複数の検索語一致、一致ハイライト、
                スペルチェックも即時に提供しています。
            </p>
            <p>
                Akahaiはの導入は非常に簡単で、容易に検索エンジン、データベース、ウェブアプリケーションと統合ができます。
                世界中の何百万のユーザに毎日使われています。
            </p>
        </Section>
        <Section title="Keywords" grey>
            <p>
                日本語向けに開発したキーフレーズ抽出器を提供しています。キーフレーズ及び概念抽出は
                例文による検索、コンテンツのパーソナル化、レコメンデーションシステム、大量のデータから大事なテーマを見分けるためなど、
                幅広いアプリケーションに応用できます。
            </p>
        </Section>
        <Section title="Entities">
            <p>
                私たちが開発した固有表現抽出器は人命、企業名、場所、施設、宗教などの固有表現を認識することができます。
                現時点では日本語、英語、北欧の言語に対応しています。言語のコンテキストを考慮しているため、
                同じ言葉を使い方に寄って正しく識別することもできますし、見知らぬ言葉も正確に認識することができます。
                機械学習のモデルは辞書などにも強化することができ、お客様の専門用語を効率よく取り入れることが可能です。
                なお、機械学習モデルにカスタムデータを構築するためユーザにとって使いやすいアノテーションツールも提供しています。
            </p>
        </Section>

        <Section title="Panda" grey>
            <p>
                パンダは中国語の簡体字のため開発した形態素解析エンジンです。
                正確に言葉を分割し、品詞タグ付けとピンインも提供しています。
                各形態素に対して繁体字の情報も含まれています。
            </p>
        </Section>

        <ProductsCallout>
            <p>
                私たちの製品はJavaで開発しています。製品モジュールはRESTサーバを提供していますのであらゆるプラットフォームまたは
                プログラミング言語から統合が簡単にできます。
            </p>
        </ProductsCallout>

        <Customers title="顧客" />

        {/*<Testimonials title="お客様の声">*/}
        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Ut tincidunt metus eget velit convallis, ut placerat sem aliquet.*/}
        {/*Aliquam hendrerit.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Phasellus in turpis feugiat neque elementum dictum vitae vel sem.*/}
        {/*In hac habitasse platea dictumst. Donec vulputate.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme, Inc - J. Doe">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*In et dui quis elit malesuada ultrices.*/}
        {/*Etiam vitae urna at dolor mollis maximus.*/}
        {/*Quisque risus mauris, elementum id euismod a, fermentum.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}

        {/*<Testimonial title="Acme Inc, Pieter-Jan">*/}
        {/*<p>*/}
        {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
        {/*Suspendisse eget augue magna.*/}
        {/*Aenean urna magna, congue id nisi at, euismod blandit est.*/}
        {/*Morbi sit amet hendrerit massa, sed fringilla odio.*/}
        {/*Sed vel ornare purus.*/}
        {/*In lobortis, metus sit amet faucibus consequat,*/}
        {/*urna turpis facilisis nunc, sit amet finibus purus mi.*/}
        {/*</p>*/}
        {/*</Testimonial>*/}
        {/*</Testimonials>*/}

        <ContactUs title="Let’s get to work">
            <p>プロジェクトのご相談を承っています。ご連絡をお待ちしております。</p>
        </ContactUs>
        <Footer lang="ja" />
    </Page>
);

export default memo(ProductsJa);
